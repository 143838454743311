import { createRoot } from 'react-dom/client';
import { SiteFooter } from 'bv-components';

export const bootFooter = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('footer-root');
    const root = createRoot(container);
    root.render(<SiteFooter className="lp" />);
  });
};
